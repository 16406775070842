import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Tab } from '@headlessui/react';
import Panel from '../../../../shared-components/panel/Panel';
import { Spinner } from '../../../../shared-components/Spinner';
import bookingApi from '../../../../../api/bookingApi';
import {
  notificationsAtom,
  reloadBookingsAtom,
  selectedBookingAtom,
  loadingBookingsAtom
} from '../../../../../atoms/Atoms';
import NewCardModal from '../payments/shared-components/StripeModal';
import Booking from './shared-components/Booking';
import Button from '../../../../shared-components/buttons/Button';

function Reservations() {
  const { t } = useTranslation();
  const setLoadingBookings = useSetRecoilState(loadingBookingsAtom);
  const [selectedBooking, setSelectedBooking] = useRecoilState(selectedBookingAtom);
  const [reloadBookings, setReloadBookings] = useRecoilState(reloadBookingsAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [pageSize, setPageSize] = useState(15);
  const [selectedTab, setSelectedTab] = useState(-1);
  const [loading, setLoading] = useState(false);
  // const [booking, setBooking] = useState(null);
  const [tabs, setTabs] = useState([   
    {
      key: 'upcoming',
      title: t('upcoming'),
      show: true,
      count: 0,
      page: 0,
      type: 1,
      bookings: []
    },
    {
      key: 'pendingpayment',
      title: t('pending_payment'),
      show: true,
      count: 0,
      page: 0,
      type: 0,
      bookings: []
    },
    {
      key: 'current',
      title: t('active'),
      show: true,
      count: 0,
      page: 0,
      type: 2,
      bookings: []
    },
    {
      key: 'past',
      title: t('past'),
      show: true,
      count: 0,
      page: 0,
      type: 3,
      bookings: []
    },
    {
      key: 'cancelled',
      title: t('cancelled'),
      show: true,
      count: 0,
      page: 0,
      type: 4,
      bookings: []
    }
  ]);

  function getBookings(page, type) {
    setLoading(true);
    bookingApi.get(`events/v2/grouped/bookings?page=${page}&size=${pageSize}&type=${type}`)
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          const temp = [...tabs];
          for (let i = 0; i < temp.length; i++) {
            if (temp[i].key === 'past') {
              temp[i].bookings = temp[i].bookings.concat(response.data.data.past);
            } else if (temp[i].key === 'upcoming') {
              temp[i].bookings = temp[i].bookings.concat(response.data.data.upcoming);
            } else if (temp[i].key === 'current') {
              temp[i].bookings = temp[i].bookings.concat(response.data.data.current);
            } else if (temp[i].key === 'pendingpayment') {
              temp[i].bookings = temp[i].bookings.concat(response.data.data.pendingPayment);
            } else if (temp[i].key === 'cancelled') {
              temp[i].bookings = temp[i].bookings.concat(response.data.data.cancelled);
            }
          }
          setTabs(temp);
        } else {
          setNotifications([
            ...notifications,
            {
              title: `${t('load_error')}`,
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setNotifications([
          ...notifications,
          {
            title: `${t('load_error')}`,
            description: err.message,
            error: true,
          },
        ]);
      });
  }

  function getBookingsHeader() {
    setLoading(true);
    setLoadingBookings(true);
    // setBooking(null);
    bookingApi.get('events/v2/grouped/bookings?page=0&size=0')
      .then((response) => {
        setLoading(false);
        setLoadingBookings(false);
        if (response.data.success) {
          // setBooking(response.data.data);
          const temp = [...tabs];
          // let allBookings = [];
          for (let i = 0; i < temp.length; i++) {
            if (temp[i].key === 'past') {
              temp[i].show = response.data.data.totalPast > 0;
              temp[i].count = response.data.data.totalPast;
            } else if (temp[i].key === 'upcoming') {
              temp[i].show = response.data.data.totalUpcoming > 0;
              temp[i].count = response.data.data.totalUpcoming;
            } else if (temp[i].key === 'current') {
              temp[i].show = response.data.data.totalCurrent > 0;
              temp[i].count = response.data.data.totalCurrent;
            } else if (temp[i].key === 'pendingpayment') {
              temp[i].show = response.data.data.totalPendingPayment > 0;
              temp[i].count = response.data.data.totalPendingPayment;
            } else if (temp[i].key === 'cancelled') {
              temp[i].show = response.data.data.totalCancelled > 0;
              temp[i].count = response.data.data.totalCancelled;
            }
          }
          if (temp[0].show && selectedTab !== 0) {
            setSelectedTab(0);
          } else if (temp[1].show && selectedTab !== 1) {
            setSelectedTab(1);
          } else if (temp[2].show && selectedTab !== 2) {
            setSelectedTab(2);
          } else if (temp[3].show && selectedTab !== 3) {
            setSelectedTab(3);
          } else if (temp[4].show && selectedTab !== 4) {
            setSelectedTab(4);
          } else if (selectedTab === -1) {
            setSelectedTab(0);
          } else {
            temp[selectedTab].page = 0;
            temp[selectedTab].bookings = [];
            getBookings(0, temp[selectedTab].type);
          }
          setTabs(temp);
        } else {
          setNotifications([
            ...notifications,
            {
              title: `${t('load_error')}`,
              description: response.data.error,
              error: true,
            },
          ]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingBookings(false);
        setNotifications([
          ...notifications,
          {
            title: `${t('load_error')}`,
            description: err.message,
            error: true,
          },
        ]);
      });
  }

  useEffect(() => {
    getBookingsHeader();
  }, []);

  useEffect(() => {
    if (reloadBookings) {
      setReloadBookings(false);
      getBookingsHeader();
      setSelectedTab(-1);

      if (selectedBooking) {
        bookingApi.get(`events/booking/${selectedBooking.id}`)
        .then((response) => {
          setLoading(false);
          if (response.data.success) {
            setSelectedBooking(response.data.data);
          } else {
            setSelectedBooking(null);
            setNotifications([
              ...notifications,
              {
                title: `${t('load_error')}`,
                description: response.data.error,
                error: true,
              },
            ]);
          }
        })
        .catch((err) => {
          setLoading(false);
          setLoadingBookings(false);
          setSelectedBooking(null);
          setNotifications([
            ...notifications,
            {
              title: `${t('load_error')}`,
              description: err.message,
              error: true,
            },
          ]);
        });
        // const filtered = _.filter(allBookings, (b) => b.id === selectedBooking.id);
        // if (filtered.length > 0) {
        //   setSelectedBooking(filtered[0]);
        // }
      }
    }
  }, [reloadBookings]);

  useEffect(() => {
    if (selectedTab >= 0) {
      const tempTabs = [...tabs];
      tempTabs[selectedTab].page = 0;
      tempTabs[selectedTab].bookings = [];
      setTabs(tempTabs);
      getBookings(0, tempTabs[selectedTab].type);
    }
  }, [selectedTab]);
  return (
    <div className="flex flex-col flex-1 px-4 mb-4 overflow-auto space-y-4">
      <Panel>
        {tabs && tabs?.length === 0 && <div className="">You have no reservations</div>}
        <Tab.Group selectedIndex={selectedTab} onChange={(index) => setSelectedTab(index)}>
          <Tab.List className="flex bg-blue-500 space-x-1 p-1 md:p-2 bg-opacity-20 rounded-xl text-xs">

            {_.map(tabs, (e) => (
              <Tab
                disabled={!e.show}
                key={e.key}
                className={({ selected }) => {
                  if (selected) {
                    return 'bg-blue-400 tracking-tight shadow text-white w-full py-2.5 px-1 ring-1 ring-offset-1 text-xs md:text-md md:leading-5 md:font-medium rounded-md outline-none focus:outline-none';
                  }
                  return `text-gray-700 w-full py-2.5 text-xs md:text-sm font-light tracking-tight md:leading-5 font-medium rounded-md ${e.show ? 'hover:bg-white hover:bg-opacity-50' : 'cursor-not-allowed'}`;
                }}
              >
                <div>{e.title}</div>
                <div>{!loading && e.count > 0 ? `(${e.count})` : ''}</div>
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="mt-2">
            {_.map(tabs, (tab) => (
              <Tab.Panel className="p-2">
                <Spinner spinning={loading} className="mx-auto" />
                {_.map(tab?.bookings, (b) => (
                  <Booking booking={b} />
                ))}
                <div className="flex">
                  {(tabs[selectedTab]?.page + 1) * pageSize <= tabs[selectedTab]?.count && (
                    <Button
                      text={t('load_more')}
                      textColor="text-white"
                      disabled={loading}
                      loading={loading}
                      onClick={() => {
                        const tempTabs = [...tabs];
                        tempTabs[selectedTab].page++;
                        setTabs(tempTabs);
                        getBookings(tempTabs[selectedTab].page, tempTabs[selectedTab].type);
                      }}
                      loaderColor="bg-white"
                      className="mx-auto mt-4 bg-customgreen hover:ring-2 ring-offset-2 ring-green-500 text-xl"
                    />
                  )}
                </div>
              </Tab.Panel>
            ))}           
          </Tab.Panels>
        </Tab.Group>
      </Panel>
      <NewCardModal />
    </div>
  );
}

export default Reservations;
