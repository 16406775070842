import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { useTranslation } from 'react-i18next';
import {
  useRecoilValue,
  useSetRecoilState,
  useRecoilState,
  useResetRecoilState
} from 'recoil';
import _ from 'lodash';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useMount, useLocalStorage, useLocation } from 'react-use';
import { AnimatePresence } from 'framer-motion';
import {
  locationAtom,
  orderAtom,
  serviceAtom,
  storeAtom,
  themeAtom,
  tokenAtom,
  userAtom,
  loyaltyPointsAtom
} from '../atoms/Atoms';
import routes from '../routes';
import Notifications from './shared-components/Notifications';
import Sidebar from './shared-components/sidebar/Sidebar';
import Alert from './shared-components/Alert';
import ProtectedRoute from '../routes/ProtectedRoute';
import Header from './shared-components/header/Header';
import Subheader from './shared-components/subheader/Subheader';
import Locations from './shared-components/locations/Locations';
import Navigator from './shared-components/navigator/Navigator';
import Modal from './shared-components/modal/Modal';
import api from '../api/api';
import bookingApi from '../api/bookingApi';
import { businessesSelector } from '../atoms/Selectors';
import envConfig from '../envConfig';
import RegisterLocations from './shared-components/register-location/RegisterLocations';
import Popup from './shared-components/popup/Popup';
import DetailsModal from './pages/profile/shared-components/orders/shared-components/details-modal/DetailsModal';
import BookingDetailsModal from './pages/profile/shared-components/reservations/shared-components/BookingDetailsModal';
import StripeModal from './pages/profile/shared-components/payments/shared-components/StripeModal';
import BrainTreeModal from './pages/profile/shared-components/payments/shared-components/BrainTreeModal';

function App() {
  if (!String.prototype.format) {
    String.prototype.format = function () {
      const args = arguments;
      return this.replace(/{(\d+)}/g, function (match, number) { 
        return typeof args[number] !== 'undefined'
          ? args[number]
          : match;
      });
    };
  }

  const { i18n } = useTranslation();
  const location = useLocation();
  const refreshStore = useResetRecoilState(businessesSelector);
  // Global States (Atoms)
  const theme = useRecoilValue(themeAtom);
  const setService = useSetRecoilState(serviceAtom);
  const [store, setStore] = useRecoilState(storeAtom);
  const setToken = useSetRecoilState(tokenAtom);
  const [userA, setUser] = useRecoilState(userAtom);
  const setLoyaltyPoints = useSetRecoilState(loyaltyPointsAtom);
  const [locations, setLocations] = useRecoilState(locationAtom);
  const [order, setOrder] = useRecoilState(orderAtom);
  // Local storage on mount
  const [service] = useLocalStorage(`${envConfig.StorageKey}-service`);
  const [storagestore] = useLocalStorage(`${envConfig.StorageKey}-store`);
  const [token] = useLocalStorage(`${envConfig.StorageKey}-token`);
  const [language] = useLocalStorage(`${envConfig.StorageKey}-lang`);
  const [user] = useLocalStorage(`${envConfig.StorageKey}-user`);
  const [localLocation] = useLocalStorage(`${envConfig.StorageKey}-location`);
  const [value, setValue, remove] = useLocalStorage(`${envConfig.StorageKey}-order`);
  const [config, setConfig] = useState({});
  const [fetchedState, setFetchedState] = useState(false);
  const [registerLocation, setRegisterLocation] = useRecoilState(locationAtom);

  if (envConfig.GoogleAnalyticsTrackingId) {
    ReactGA.initialize(envConfig.GoogleAnalyticsTrackingId);
  }

  if (envConfig.FacebookPixelId) {
    const advancedMatching = { em: user ? user.email : '' };
    const options = {
      autoConfig: true,
      debug: false
    };
    ReactPixel.init(envConfig.FacebookPixelId, advancedMatching, options);
  }


  function refreshConfig() {
    api.get('utility/file/read/web/config')
    .then((response) => {
      setConfig(response.data);
    })
    .catch((err) => {
      console.error(err.message);
    });
  }

  function updateLanguage() {
    if (userA) {
      api.post('users/me', {
        preferredLanguage: i18n.language === 'en' ? 0 : 1
      });
    }
  }

  function getCurrentUserState() {
    if (userA && !fetchedState) {
      setFetchedState(true);
      bookingApi.get('auth/refreshstate')
        .then((response) => {
          console.log('refreshstate', response.data.data.profile);
          localStorage.setItem(
            `${envConfig.StorageKey}-user`,
            JSON.stringify(response.data.data.profile)
          );
          setUser(response.data.data.profile);
        }).catch((err) => {
          setUser(null);
          setToken(null);
          localStorage.removeItem(`${envConfig.StorageKey}-user`);
          localStorage.removeItem(`${envConfig.StorageKey}-token`);
        });
    } else if (!user) {
      setFetchedState(false);
    }
  }

  useEffect(() => {
    updateLanguage();
  }, [language, i18n.language, userA]);

  useEffect(() => {
    if (envConfig.FacebookPixelId) {
      ReactPixel.pageView(); // For tracking page view
    }
    if (envConfig.GoogleAnalyticsTrackingId) {
      ReactGA.pageview(location.pathname);
    }
  }, [location]);

  // Send local storage to global state on mount
  useMount(() => {
    setInterval(() => {
      refreshConfig();
    }, 300000);
    refreshStore();
    refreshConfig();
    
    if (language) i18n.changeLanguage(language);

    if (storagestore) {
      setStore(storagestore);
    }

    if (token) {
      setToken(token);
    }

    if (user) {
      setUser(user);
    }

    if (value) {
      setOrder(value);
    }

    if (service) {
      setService(service);
    }

    if (localLocation) {
      setLocations({ ...locations, location: localLocation });
    }
  });

  useEffect(() => {
    localStorage.setItem(
      `${envConfig.StorageKey}-store`,
      JSON.stringify(store)
    );
  }, [store]);

  // This is the function that check everytime the order mutate
  // and persists the order on the local storage
  useEffect(() => {
    if (order.length > 0) {
      setValue(order);
    } else {
      remove();
    }
  }, [order]);

  useEffect(() => {
    getCurrentUserState();
  }, [userA]);

  useEffect(() => {
    api.get('loyalty/points')
      .then((response) => {
        setLoyaltyPoints(response.data.data);
      })
  }, []);

  return (
    <div
      className={`${theme} flex flex-col md:h-screen md:overflow-hidden bg-gray-100 text-gray-800 font-sans antialiased`}
    >
      <Router>
        <Notifications />
        <Locations />
        <RegisterLocations />
        <Sidebar />
        <Alert />
        <DetailsModal />
        <BookingDetailsModal />
        <Header />
        {/* <Subheader />         */}
        <Navigator />
        <Modal />
        {envConfig.BrainTreeTokenizationKey.length > 0 ? <BrainTreeModal /> : <StripeModal />}
        {/* <Popup />        */}
        <AnimatePresence exitBeforeEnter initial={false}>
          <Switch location={location} key={location.pathname}>
            {_.map(routes, (route) => {
              if (route.protected) {
                return (
                  <ProtectedRoute
                    key={route.id}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                  />
                );
              }

              return (
                <Route
                  key={route.id}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              );
            })}
          </Switch>
        </AnimatePresence>
      </Router>
      {config?.MaintenanceMode && (
        <div
          className="fixed bg-gray-800 inset-0 bg-opacity-75 z-10 flex items-end md:items-center justify-center px-0 md:px-4"
        >
          <div className="text-gray-800 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="mx-auto max-w-md px-4">
              <div className="text-center rounded-xl shadow-lg relative flex flex-col px-4 py-2 w-full bg-white">
                {config?.MaintenanceModeTextEng?.length > 0 && (
                  <>{config?.MaintenanceModeTextEng}</>
                )}
                {config?.MaintenanceModeTextEng?.length > 0 &&
                config?.MaintenanceModeTextSpa?.length > 0 && (
                  <hr className="my-2" />
                )}
                {config?.MaintenanceModeTextSpa?.length > 0 && (
                  <>{config?.MaintenanceModeTextSpa}</>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
